import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  accordionExpandable: css`
    padding-vertical: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
  `,
  accordionLeft: css`
    flex-direction: row;
    gap: 8px;
    align-items: center;
  `,
  profileAccordion: css`
    margin-top: 16px;
    margin-bottom: 16px;
  `,
  marginThirtyTwoTop: css`
    flex-direction: row;
    margin-top: 32px;
  `,
  horizontalRow: css`
    border-bottom-color: #d3d3d3;
    border-bottom-width: 1px;
    width: 100%;
  `,
  adWrapper: css`
    margin-top: 32px;
  `
};
