import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './MobileFooter.base.styles';

const footerWebStyles = css`
  ${baseStyles.footer};
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 3;
`;

export const styles = {
  footer: css`
    ${footerWebStyles}
    background-color: #f6f6fa;
  `
};

export const legacyStyles = {
  footer: css`
    ${footerWebStyles}
    background-color: white;
  `
};
