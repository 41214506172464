import React from 'react';
import { styles } from './base.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import useGetProfileMenuInfo from '../../hooks/useGetProfileMenuInfo';
import ProfileAccordionItem from './ProfileAccordionItem';
import { AdBanner } from '@gf/cross-platform-lib/components';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

export const ProfileAccordionMenu = () => {
  const headersToSubHeadersActions = useGetProfileMenuInfo();
  const { isNativeApp } = useMediaQuery();
  const launchDarklyContext = useLaunchDarklyContext();
  const { variation: adsEnabled = false } = launchDarklyContext?.features['ads-enabled'] || {};

  return (
    <ProfileAccordion>
      {Array.from(headersToSubHeadersActions.entries()).map(headerToSubHeadersActions => {
        const header = headerToSubHeadersActions[0];
        const subHeadersToActions = headerToSubHeadersActions[1].subHeaderAction;
        const expandable = headerToSubHeadersActions[1].expandable;
        return (
          <View key={header}>
            {adsEnabled && header === 'More resources' && (
              <AdWrapper>
                <AdBanner
                  sizes={isNativeApp ? ['MEDIUM_RECTANGLE'] : [300, 250]}
                  unitId={'/6355419/Travel/Europe/France/Paris'}
                  slotId={`${header}`}
                />
              </AdWrapper>
            )}
            <ProfileAccordionItem header={header} subHeadersToActions={subHeadersToActions} expandable={expandable} />
          </View>
        );
      })}
    </ProfileAccordion>
  );
};

const ProfileAccordion = styled(View)`
  ${styles.profileAccordion};
`;
const AdWrapper = styled(View)`
  ${styles.adWrapper};
`;

export default ProfileAccordionMenu;
