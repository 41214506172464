import { css } from 'styled-components';

const sharedWrapperStyles = `
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
`;

export const styles = {
  wrapper: css<{ active: boolean }>`
    ${sharedWrapperStyles}
    padding-top: 14px;
    background-color: ${({ active = false }) => (active ? '#ECECF0' : '#F6F6FA')};
  `,

  wrapperLegacy: css`
    ${sharedWrapperStyles}
    padding-top: 7px;
  `,
  iconWrapper: css`
    margin: auto;
  `,
  label: css<{ active: boolean }>`
    font-size: 12px;
    font-family: Roboto;
    font-family: ${({ active = false }) => (active ? 'Roboto-Bold' : 'Roboto-Regular')};
    letter-spacing: 0.16px;
    line-height: 16px;
    text-align: center;
    color: ${({ active = false }) => (active ? '#191919' : '#4A4A4B')};
    margin-top: 5px;
    padding-bottom: 8px;
  `,
  labelLegacy: css<{ active: boolean }>`
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    text-align: center;
    color: ${({ active = false }) => (active ? '#282828' : '#5b5b5b')};
    padding-top: 5px;
  `
};
