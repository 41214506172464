import React, { useEffect } from 'react';
import styled from 'styled-components';
import { styles } from './base.styles';

type AdBannerProps = {
  unitId: string;
  sizes: string[] | number[] | [number, number];
  requestOptions?: string;
  slotId?: string;
};

export function getMinimumSlotSize(size: string | any[]) {
  const maxValue = Number.MAX_VALUE;

  let minW = Number.MAX_VALUE;
  let minH = Number.MAX_VALUE;

  if (Array.isArray(size)) {
    // Convert googletag.SingleSize to googletag.MultiSize for convenience.
    const sizes = size.length <= 2 && !Array.isArray(size[0]) ? [size] : size;

    for (const size of sizes) {
      if (Array.isArray(size) && size[0] !== 'fluid') {
        minW = Math.min(size[0], minW);
        minH = Math.min(size[1], minH);
      }
    }
  }

  return minW < maxValue && minH < maxValue
    ? // Static ad slot.
      { minWidth: `${minW}px`, minHeight: `${minH}px` }
    : // Fluid ad slot.
      { minWidth: '50%' };
}

const GOOGLE_AD_SCRIPT_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

export const AdBanner = ({ unitId, sizes, slotId }: AdBannerProps) => {
  if (typeof window !== 'undefined' && !window.googletag) {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      googletag.pubads().disableInitialLoad();
      googletag.enableServices();
    });
  }

  if (window.document && !window.document.querySelector(`script[src="${GOOGLE_AD_SCRIPT_URL}"]`)) {
    const script = window.document.createElement('script');
    script.src = GOOGLE_AD_SCRIPT_URL;
    script.async = true;
    window.document.head.appendChild(script);
  }

  useEffect(() => {
    googletag.cmd.push(() => {
      const slot = googletag.defineSlot(unitId, [sizes as [number, number]], slotId);
      if (slot) {
        slot.addService(googletag.pubads());
        googletag.display(slotId as string);
        googletag.pubads().refresh([slot]);
      }
    });

    return () => {
      googletag.cmd.push(() => {
        const slot = googletag
          .pubads()
          .getSlots()
          .find(slot => slot.getSlotElementId() === slotId);
        if (slot) googletag.destroySlots([slot]);
      });
    };
  }, [sizes, slotId, unitId]);

  return (
    <AdWrapper data-testid={`test-ad-banner-${unitId}`}>
      <div id={slotId} style={getMinimumSlotSize(sizes)}></div>
    </AdWrapper>
  );
};

const AdWrapper = styled.div`
  ${styles.WebContent}
`;

export default AdBanner;
