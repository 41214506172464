import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  AdBannerWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  WebContent: css`
    max-width: 100%;
    align-items: center;
  `
};
