import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  footer: css`
    width: 100%;
    height: 62px;
    z-index: 1;
    flex-direction: row;
    left: 0;
    bottom: 0;
  `
};
